<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
      <c-table
        ref="tableItem"
        title="점검항목 목록"
        :columns="gridItem.columns"
        :data="dangerStore.itemList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        noDataLabel="점검항목을 추가하세요."
        :hideBottom="true"
        :editable="editable"
        rowKey="chmDangerMstItemId"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn 
              v-if="editable" 
              :showLoading="false" 
              label="LBLADD" 
              icon="add" 
              @btnClicked="addItem" />
            <c-btn
              v-if="editable"
              :url="saveItemUrl"
              :isSubmit="isSaveItem"
              :param="dangerStore.itemList"
              mappingType="PUT"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveItem"
              @btnCallback="saveItemCallback" 
            />
            <c-btn 
              v-if="editable && dangerStore.itemList && dangerStore.itemList.length > 0" 
              :showLoading="false"  
              label="삭제" 
              icon="remove" 
              @btnClicked="removeItem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <c-table
        ref="tableFireFighting"
        title="관련 소방시설 목록"
        :columns="gridFire.columns"
        :data="dangerStore.fireFightings"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        noDataLabel="소방시설을 추가하세요."
        :hideBottom="true"
        :editable="editable"
        rowKey="sopFireFightingId"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn 
              v-if="editable" 
              :showLoading="false" 
              label="LBLADD" 
              icon="add" 
              @btnClicked="addFireFighting" />
            <c-btn 
              v-if="editable && dangerStore.fireFightings && dangerStore.fireFightings.length > 0" 
              :showLoading="false"  
              label="삭제" 
              icon="remove" 
              @btnClicked="removeFireFighting" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'dangerMasterEtc',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmDangerMstId: '',
      }),
    },
    dangerStore: {
      type: Object,
      default: () => ({
        chmDangerMstId: '',
        plantCd: '',
        chmDangerArea: '',
        chmDangerManageUserId: '',
        chmDangerMstName: '',
        chmDangerUserId: '',
        chmDangerSafetyUserId: '',
        chmDangerCount: '',
        chmDangerMainUse: '',
        installPermitDate: '',
        permitNo: '',
        useFlag: 'Y',
        chmDangerTypeCd: null,
        typeList: [],
        deleteTypeList: [],
        itemList: [],
        fireFightings: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: () => false
    },
    contentHeight: null,
  },
  data() {
    return {
      gridItem: {
        columns: [
          {
            required: true,
            name: 'dangerCheckItem',
            field: 'dangerCheckItem',
            label: '점검항목',
            style: 'width:300px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            required: true,
            name: 'dangerCheckContent',
            field: 'dangerCheckContent',
            label: '점검내용',
            align: 'left',
            style: 'width: 300px',
            sortable: true,
            type: 'text',
          },
          {
            name: 'dangerCheckMethod',
            field: 'dangerCheckMethod',
            label: '점검방법',
            align: 'left',
            style: 'width: 100px',
            sortable: true,
            type: 'text',
          },
        ],
      },
      gridFire: {
        columns: [
          {
            name: 'manageNo',
            field: 'manageNo',
            label: '설치번호',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'fireFightingName',
            field: 'fireFightingName',
            label: '소화설비',
            align: 'left',
            sortable: true,
            style: 'width: 200px',
            type: 'link'
          },
          {
            name: 'sopFireFightingTypeName',
            field: 'sopFireFightingTypeName',
            label: 'LBLTYPE',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
        ],
      },
      editable: true,
      isSaveItem: false,
      itemListUrl: '',
      saveItemUrl: '',
      deleteItemUrl: '',
      fireListUrl: '',
      saveFireUrl: '',
      deleteFireUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.itemListUrl = selectConfig.chm.danger.item.list.url;
      this.fireListUrl = selectConfig.fft.fireFighting.list.url
      this.saveItemUrl = transactionConfig.chm.danger.item.save.url;
      this.deleteItemUrl = transactionConfig.chm.danger.item.delete.url;
      this.saveFireUrl = transactionConfig.chm.danger.fireFighting.save.url;
      this.deleteFireUrl = transactionConfig.chm.danger.fireFighting.delete.url;
      // list setting
    },
    getItemList() {
      this.$http.url = this.itemListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        chmDangerMstId: this.popupParam.chmDangerMstId
      };
      this.$http.request((_result) => {
        this.$set(this.dangerStore, 'itemList', _result.data)
      },);
    },
    getFireFightingList() {
      this.$http.url = this.fireListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        chmDangerMstId: this.popupParam.chmDangerMstId
      };
      this.$http.request((_result) => {
        this.$set(this.dangerStore, 'fireFightings', _result.data)
      },);
    },
    addItem() {
      this.popupOptions.title = '정기점검항목';
      this.popupOptions.target = () => import(`${'@/pages/chm/base/dangerStorageCheckItemPop.vue'}`);
      this.popupOptions.param = {
        type: 'multiple',
        chmDangerTypeCd: this.dangerStore.chmDangerTypeCd
      };
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeItemPopup;
    },
    closeItemPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.dangerStore.itemList.push({
            chmDangerMstId: this.popupParam.chmDangerMstId,
            chmDangerMstItemId: uid(),
            dangerCheckItem: item.dangerCheckItem,
            dangerCheckContent: item.dangerCheckContent,
            dangerCheckMethod: item.dangerCheckMethod,
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C'
          })
        });
      }
    },
    removeItem() {
      let selectData = this.$refs['tableItem'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteItemUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.dangerStore.itemList = this.$_.reject(this.dangerStore.itemList, item)
              })
              this.$refs['tableItem'].$refs['compo-table'].clearSelection();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveItem() {
      if (this.$comm.validTable(this.gridItem.columns, this.dangerStore.itemList)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSaveItem = !this.isSaveItem;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveItemCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getItemList();
    },
    addFireFighting() {
      this.popupOptions.title = '소방설비';
      this.popupOptions.target = () => import(`${'@/pages/fft/fireFightingPop.vue'}`);
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFireFightingItemPopup;
    },
    closeFireFightingItemPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.dangerStore.fireFightings, { sopFireFightingId: item.sopFireFightingId }) === -1) {
            this.dangerStore.fireFightings.push(this.$_.extend(item, {
              chmDangerMstId: this.popupParam.chmDangerMstId,
              editFlag: 'C'
            }))
          }
        })
        this.$http.url = this.saveFireUrl;
        this.$http.type = 'PUT';
        this.$http.param = this.dangerStore.fireFightings
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getFireFightingList();
        },);
      }
    },
    removeFireFighting() {
      let selectData = this.$refs['tableFireFighting'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteFireUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.dangerStore.fireFightings = this.$_.reject(this.dangerStore.fireFightings, item)
              })
              this.$refs['tableFireFighting'].$refs['compo-table'].clearSelection();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
